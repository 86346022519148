import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout/layout"
import Seo from "../components/seo"
import Sidebar from "../components/homeMenu/sideBar"
import { useTranslation } from 'gatsby-plugin-react-i18next'
import styled from "styled-components"
import { Image } from '../components/Images'
import Projetos from "../components/Projects"
import ProjectsTitle from "../components/ProjectsTitle"

const ProjetosPage = ({ location }) => {
    const { t } = useTranslation()

    var dataProjetos = t("projetos", { returnObjects: true })
    var dataGlobal = t("global", { returnObjects: true })

    return (
        <Layout
            data={dataGlobal}
            location={location}
        >
            <Seo title="Projetos " />
            <Sidebar
                content={dataGlobal.sidebar}
                projetosPortfolio={t("portfolio", { returnObjects: true }).projetos}
                projetosVenda={t("projetosVenda", { returnObjects: true }).projetos}
            />
            <StyledTitle>
                <Image src='venda/background.png' alt='Edifício' background />
                <ProjectsTitle
                    title={dataProjetos.title}
                />
            </StyledTitle>
            <Projetos
                data={dataProjetos.cards}
            />
        </Layout>
    )
}

export default ProjetosPage

export const pageQuery = graphql`
  query($language: String!) {
    locales: allLocale(filter: {ns: {in: ["projetosVenda", "global", "projetosVenda", "portfolio", "projetos"]}, language: {eq: $language}}) {
        edges {
          node {
            ns
            data
            language
          }
        }
    }
  }
`

const StyledTitle = styled.div`
    .background{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index:-1;
    }
`